<template>
    <div class="container">
        <div class="large-12 medium-12 small-12 cell">
            <label>File
                <input type="file" id="file" ref="file" v-on:change="handleFileUpload()"/>
            </label>
            <button v-on:click="submitFile()">Отправить</button>
        </div>
    </div>
</template>

<script>
export default {
  props: ['model'],
  data () {
    return {
      file: '',
      local: this.model
    }
  },
  watch: {
    local () {
      this.model = this.local
    },
    model () {
      this.local = this.model
    }
  },
  methods: {
    submitFile () {
      let formData = new FormData()
      formData.append('file', this.file)
      let obj = `{"constant_fields": [{"field_id":208,"value":${this.local},"is_key":true}]}`
      formData.append('payload', obj)
      this.$http.post(`${this.$config.api}/etleditor/import/9`,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        }
      ).then(function () {
        console.log('SUCCESS!!')
      })
        .catch(function () {
          console.log('FAILURE!!')
        })
    },
    handleFileUpload () {
      this.file = this.$refs.file.files[0]
    }
  }
}
</script>
